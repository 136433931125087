import React, { useState } from "react";
import styled from "styled-components";
import { backgroundGrey, darkBlue } from "site-ui/colors";
import { Chevron } from "../../icons/Chevron";

export const Accordion = ({ questions }) => {
  return (
    <AccordionWrapper>
      {questions.map((question) => (
        <AccordionItem question={question.question} answer={question.answer} />
      ))}
    </AccordionWrapper>
  );
};

const AccordionItem = ({ question, answer }) => {
  const [isOpen, setOpen] = useState(false);
  return [
    <Dt>
      <AccordionQuestion
        onClick={() => setOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        <IconContainer>
          <Chevron top={isOpen ? true : false} width="24" height="24" />
        </IconContainer>
        <Question>{question}</Question>
      </AccordionQuestion>
    </Dt>,
    <Answer id="myId-1" hidden={!isOpen}>
      {answer}
    </Answer>,
  ];
};

const AccordionWrapper = styled.dl`
  border-top: 1px solid ${backgroundGrey};
  max-width: 800px;
  width: 100%;
  text-align: left;
`;

const Dt = styled.dt`
  padding: 10px 0;
  display: flex;
  align-items: center;
  border-top: 1px solid ${backgroundGrey};
  margin-top: -1px;
  border-bottom: 1px solid ${backgroundGrey};
  @media (min-width: 640px) {
    min-height: 64px;
  }
`;
const Question = styled.span``;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Answer = styled.dd`
  padding: 12px 0 24px;
  @media (min-width: 640px) {
    padding: 24px 20px 36px 40px;
  }
`;
const AccordionQuestion = styled.a`
  display: flex;
  & > :first-child {
    width: 32px;
    flex-shrink: 0;
  }
  font-family: Maersk Text, sans-serif;
  border: none;
  font-size: 18px;
  font-weight: bold;
  color: ${darkBlue};
  cursor: pointer;
  &:focus {
    outline: 2px solid ${darkBlue};
  }
  ${Question} {
    color: ${darkBlue};
  }
  @media (min-width: 640px) {
    font-size: 20px;
    & > :first-child {
      width: 40px;
      flex-shrink: 0;
    }
  }
`;
