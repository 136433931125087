import React from "react";

export const Illustration = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1057"
      viewBox="0 0 1057 305"
      {...props}
    >
      <g fill="none" fill-rule="evenodd">
        <g>
          <g>
            <g transform="translate(-431 -595) translate(431 596)">
              <rect width="1057" height="206" y="70" fill="#C0F2E9" rx="24" />
              <g>
                <path
                  fill="#E0E0E0"
                  d="M612.962 74.77L619.85 74.77 619.85 67.883 612.962 67.883z"
                  transform="translate(76)"
                />
                <path
                  fill="#455A64"
                  d="M783.175 181.29v116.807h122.986V181.29c0-8.297-6.73-15.025-15.035-15.025h-92.944c-8.294.016-15.007 6.74-15.007 15.025"
                  transform="translate(76)"
                />
                <path
                  stroke="#000"
                  stroke-width=".8"
                  d="M783.175 181.29v116.807h122.986V181.29c0-8.297-6.73-15.025-15.035-15.025h-92.944c-8.294.016-15.007 6.74-15.007 15.025z"
                  transform="translate(76)"
                />
                <path
                  fill="#000"
                  d="M896.03 231.69c0 36.13.122 65.423.291 65.423.17 0 .293-29.292.293-65.423 0-36.132-.124-65.425-.293-65.425-.157 0-.291 29.293-.291 65.425"
                  transform="translate(76)"
                />
                <path
                  fill="#142146"
                  d="M806.786 15.861l-47.224 99.477c29.499.252 58.998.501 88.499.753 13.266-45.74 7.44-87.526-16.898-105.15-4.428-3.205-10.357-6.256-18.333-7.99"
                  transform="translate(76)"
                />
                <path
                  fill="#266593"
                  d="M693.694 291.21c23-6.556 46.002-13.116 69-19.673.767-4.944 1.531-9.89 2.294-14.836l2.445-121.918c-.918.215-2.428.618-4.204 1.327 0 0-9.635 3.85-17.23 15.679-6.412 9.99-13.559 44.502-14.096 91.92l-52.036 23.084 13.827 24.417z"
                  transform="translate(76)"
                />
                <path
                  stroke="#000"
                  stroke-width=".5"
                  d="M693.694 291.21c23-6.556 46.002-13.116 69-19.673.767-4.944 1.531-9.89 2.294-14.836l2.445-121.918c-.918.215-2.428.618-4.204 1.327 0 0-9.635 3.85-17.23 15.679-6.412 9.99-13.559 44.502-14.096 91.92l-52.036 23.084 13.827 24.417z"
                  transform="translate(76)"
                />
                <path
                  fill="#FFBE9D"
                  d="M649.278 292.344s3.623 2.887 1.25 5.257c-2.371 2.372-10.452-4.428-11.552-6.486-1.102-2.058.217-2.856.486-2.856l9.816 4.085z"
                  transform="translate(76)"
                />
                <g>
                  <path
                    fill="#F7F7F7"
                    d="M15.465 27.87c.129.097 2.278 1.73 1.858 3.184-.231.798-1.121 1.187-1.552 1.354-3.455 1.337-13.178-4.335-14.289-5.556-1.114-1.22 1.042-4.019 1.042-4.019l-.827-.984C.471 20.92.244 19.45.831 18.69c.518-.67 1.543-.625 1.684-.616-.169-.085-2.173-1.123-2.256-2.908-.044-1.002.534-1.834.715-2.096.887-1.274 1.9-1.198 2.047-2 .153-.823-.871-1.15-1.141-2.381-.296-1.348.575-2.595.855-3.001 1.78-2.55 5.545-2.23 5.768-2.206 4.46-.523 9.453-1.007 14.892-.859 6.173.167 11.836.574 11.836.574L49.877.037c4 .688 6.314 1.97 7.78 3.553 1.206 1.434 2.158 3.065 2.82 4.816 2.14 5.139 1.21 11.043-2.406 15.274l-12.33.198c-1.605 1.59-3.36 3.024-5.24 4.274-3.707 2.469-7.656 1.952-12.281 1.754-2.874-.126-6.977-.57-11.779-2.04"
                    transform="translate(76) translate(634.608 264.647)"
                  />
                  <path
                    stroke="#000"
                    stroke-width=".5"
                    d="M15.465 27.87c.129.097 2.278 1.73 1.858 3.184-.231.798-1.121 1.187-1.552 1.354-3.455 1.337-13.178-4.335-14.289-5.556-1.114-1.22 1.042-4.019 1.042-4.019l-.827-.984C.471 20.92.244 19.45.831 18.69c.518-.67 1.543-.625 1.684-.616-.169-.085-2.173-1.123-2.256-2.908-.044-1.002.534-1.834.715-2.096.887-1.274 1.9-1.198 2.047-2 .153-.823-.871-1.15-1.141-2.381-.296-1.348.575-2.595.855-3.001 1.78-2.55 5.545-2.23 5.768-2.206 4.46-.523 9.453-1.007 14.892-.859 6.173.167 11.836.574 11.836.574L49.877.037c4 .688 6.314 1.97 7.78 3.553 1.206 1.434 2.158 3.065 2.82 4.816 2.14 5.139 1.21 11.043-2.406 15.274l-12.33.198c-1.605 1.59-3.36 3.024-5.24 4.274-3.707 2.469-7.656 1.952-12.281 1.754-2.874-.126-6.977-.57-11.779-2.04"
                    transform="translate(76) translate(634.608 264.647)"
                  />
                </g>
                <path
                  fill="#142146"
                  d="M641.761 277.44c-.023.053 1.365.781 3.093 1.393.978.347 1.922.603 2.25.698.813.228 1.7.419 2.588.716.93.258 1.767.764 2.425 1.46.664.745.879 1.785.561 2.728-.186.454-.529.826-.966 1.05-.45.221-.939.356-1.439.401-.968.06-1.939.015-2.898-.133-1.641-.254-3.256-.646-4.83-1.175-1.285-.429-1.505-.55-3.209-1.116-.746-.249-1.366-.44-1.776-.566.412.172 1.003.42 1.719.71 1.49.603 2.317.93 3.178 1.24 1.593.57 3.236.992 4.908 1.259.998.168 2.013.222 3.023.163.552-.05 1.088-.198 1.586-.44.527-.265.94-.708 1.167-1.25.365-1.093.1-2.297-.694-3.14-.708-.746-1.611-1.285-2.61-1.555-.917-.295-1.795-.477-2.617-.686-1.186-.304-2.052-.589-2.251-.65-2.06-.643-3.186-1.155-3.208-1.107"
                  transform="translate(76)"
                />
                <path
                  stroke="#000"
                  stroke-width=".25"
                  d="M641.761 277.44c-.023.053 1.365.781 3.093 1.393.978.347 1.922.603 2.25.698.813.228 1.7.419 2.588.716.93.258 1.767.764 2.425 1.46.664.745.879 1.785.561 2.728-.186.454-.529.826-.966 1.05-.45.221-.939.356-1.439.401-.968.06-1.939.015-2.898-.133-1.641-.254-3.256-.646-4.83-1.175-1.285-.429-1.505-.55-3.209-1.116-.746-.249-1.366-.44-1.776-.566.412.172 1.003.42 1.719.71 1.49.603 2.317.93 3.178 1.24 1.593.57 3.236.992 4.908 1.259.998.168 2.013.222 3.023.163.552-.05 1.088-.198 1.586-.44.527-.265.94-.708 1.167-1.25.365-1.093.1-2.297-.694-3.14-.708-.746-1.611-1.285-2.61-1.555-.917-.295-1.795-.477-2.617-.686-1.186-.304-2.052-.589-2.251-.65-2.06-.643-3.186-1.155-3.208-1.107z"
                  transform="translate(76)"
                />
                <path
                  fill="#142146"
                  d="M641.987 271.04c1.646.576 2.3.936 3.313 1.3 1.423.514 3.14 1.025 5.081 1.497 1 .158 1.952.532 2.784 1.093.787.703.993 1.829.497 2.748-.469.947-1.329 1.661-2.368 1.97-.965.308-1.99.396-2.995.254-1.729-.318-3.427-.767-5.083-1.337l-3.404-.985c-.414-.14-.839-.246-1.268-.315.392.182.8.337 1.216.463l3.365 1.141c1.647.622 3.347 1.102 5.081 1.44 1.066.162 2.155.076 3.18-.248 1.145-.329 2.092-1.107 2.612-2.147.254-.537.343-1.136.254-1.724-.089-.581-.396-1.11-.863-1.485-.88-.605-1.892-1-2.956-1.163-1.942-.423-3.66-.957-5.082-1.408-1.02-.325-1.774-.7-3.354-1.172-.623-.185-1.142-.322-1.49-.412.346.109.861.274 1.48.49"
                  transform="translate(76)"
                />
                <path
                  stroke="#000"
                  stroke-width=".25"
                  d="M641.987 271.04c1.646.576 2.3.936 3.313 1.3 1.423.514 3.14 1.025 5.081 1.497 1 .158 1.952.532 2.784 1.093.787.703.993 1.829.497 2.748-.469.947-1.329 1.661-2.368 1.97-.965.308-1.99.396-2.995.254-1.729-.318-3.427-.767-5.083-1.337l-3.404-.985c-.414-.14-.839-.246-1.268-.315.392.182.8.337 1.216.463l3.365 1.141c1.647.622 3.347 1.102 5.081 1.44 1.066.162 2.155.076 3.18-.248 1.145-.329 2.092-1.107 2.612-2.147.254-.537.343-1.136.254-1.724-.089-.581-.396-1.11-.863-1.485-.88-.605-1.892-1-2.956-1.163-1.942-.423-3.66-.957-5.082-1.408-1.02-.325-1.774-.7-3.354-1.172-.623-.185-1.142-.322-1.49-.412.346.109.861.274 1.48.49z"
                  transform="translate(76)"
                />
                <path
                  fill="#000"
                  d="M645.818 269.437c-.116-.686-.463-1.326-1.002-1.838.114.667.3 1.328.551 1.966.108.667.278 1.326.508 1.969.244-.686.226-1.422-.057-2.097"
                  transform="translate(76)"
                />
                <g>
                  <path
                    fill="#F7F7F7"
                    d="M29.117 27.427s-3.738-.09-8.923-3.774c-5.188-3.685-4.443-8.808-4.89-9.862C14.857 12.74.827 6.8.539 3.862.253.922 1.561 1.001 1.968.386c.406-.618 24.491 10.646 24.491 10.646l8.678 3.296"
                    transform="translate(76) translate(644.447 257.76)"
                  />
                  <path
                    stroke="#000"
                    stroke-width=".5"
                    d="M29.117 27.427s-3.738-.09-8.923-3.774c-5.188-3.685-4.443-8.808-4.89-9.862C14.857 12.74.827 6.8.539 3.862.253.922 1.561 1.001 1.968.386c.406-.618 24.491 10.646 24.491 10.646l8.678 3.296"
                    transform="translate(76) translate(644.447 257.76)"
                  />
                </g>
                <path
                  fill="#F7F7F7"
                  d="M815.643 132.89c-7.871 10.152-31.264 11.731-31.264 11.731s-14.845-1.127-12.82-6.092c8.095-2.931 11.47-7.669 12.144-12.86.226-3.156 0-6.541-.899-9.7-.45-1.806-1.126-3.383-1.8-5.19l24.293-26.17c-1.126 14.439-.676 40.386 10.346 48.282"
                  transform="translate(76)"
                />
                <path
                  stroke="#000"
                  stroke-width=".5"
                  d="M815.643 132.89c-7.871 10.152-31.264 11.731-31.264 11.731s-14.845-1.127-12.82-6.092c8.095-2.931 11.47-7.669 12.144-12.86.226-3.156 0-6.541-.899-9.7-.45-1.806-1.126-3.383-1.8-5.19l24.293-26.17c-1.126 14.439-.676 40.386 10.346 48.282z"
                  transform="translate(76)"
                />
                <path
                  fill="#000"
                  d="M804.82 102.317c-.223 1.791-1.046 6.318-4.8 10.207-6.312 6.54-15.372 5.534-16.403 5.402-.331-1.229-.717-2.514-1.164-3.845-.401-1.198-.82-2.334-1.246-3.41 7.872-2.785 15.742-5.568 23.614-8.354"
                  transform="translate(76)"
                />
                <path
                  fill="#142146"
                  d="M650.147 288.1c.441.383.844.815 1.199 1.288.414.497.622 1.149.58 1.807-.15.703-.661 1.259-1.324 1.443-.741.202-1.523.17-2.246-.092-2.653-.841-5.239-1.894-7.74-3.149-.957-.486-1.712-.923-2.234-1.237-.26-.177-.533-.326-.822-.447.223.229.473.426.744.59.705.494 1.436.947 2.187 1.36 2.496 1.346 5.108 2.443 7.797 3.279.793.285 1.649.312 2.457.08.802-.23 1.41-.918 1.568-1.777.043-.744-.206-1.479-.686-2.029-.38-.486-.809-.924-1.277-1.31-.675-.553-1.421-1.006-2.216-1.34-.284-.142-.589-.234-.9-.275 1.04.472 2.018 1.08 2.913 1.809"
                  transform="translate(76)"
                />
                <path
                  stroke="#000"
                  stroke-width=".25"
                  d="M650.147 288.1c.441.383.844.815 1.199 1.288.414.497.622 1.149.58 1.807-.15.703-.661 1.259-1.324 1.443-.741.202-1.523.17-2.246-.092-2.653-.841-5.239-1.894-7.74-3.149-.957-.486-1.712-.923-2.234-1.237-.26-.177-.533-.326-.822-.447.223.229.473.426.744.59.705.494 1.436.947 2.187 1.36 2.496 1.346 5.108 2.443 7.797 3.279.793.285 1.649.312 2.457.08.802-.23 1.41-.918 1.568-1.777.043-.744-.206-1.479-.686-2.029-.38-.486-.809-.924-1.277-1.31-.675-.553-1.421-1.006-2.216-1.34-.284-.142-.589-.234-.9-.275 1.04.472 2.018 1.08 2.913 1.809z"
                  transform="translate(76)"
                />
                <path
                  fill="#263238"
                  d="M747.555 265.433c-.088-.473-.207-.942-.362-1.396-.172-.54-.333-1.155-.604-1.809-.595-1.548-1.333-3.042-2.198-4.465-1.082-1.743-2.334-3.38-3.737-4.886-1.433-1.474-3.012-2.81-4.71-3.987-1.394-.948-2.874-1.774-4.422-2.462-.588-.272-1.192-.514-1.806-.724-.455-.181-.922-.332-1.396-.45-.397-.14-.808-.234-1.226-.283-.03.099 1.696.548 4.269 1.778 1.503.723 2.941 1.565 4.3 2.522 1.654 1.178 3.194 2.502 4.6 3.956 1.383 1.473 2.626 3.061 3.715 4.75.873 1.391 1.63 2.85 2.26 4.359 1.075 2.59 1.406 4.279 1.517 4.279-.028-.4-.095-.794-.2-1.182"
                  transform="translate(76)"
                />
                <path
                  fill="#266593"
                  d="M783.925 122.109c.612.795-.433 2.023.146 3.884.998 3.213 5.687 4.582 7.68 4.874 5.733.84 12.224-3.845 16.593-11.54 6.456-.569 18.112-.743 31.503 4.041 14.107 5.041 23.327 13.154 27.942 17.833-.743 16.334-1.487 32.67-2.232 49.004.726 35.635 1.451 71.273 2.175 106.908-37.275-.083-74.554-.168-111.83-.25 0 0 .72-14.759.9-17.704-3.335-9.387-7.145-22.426-9.307-38.35-.818-6.013-1.291-11.629-1.52-16.912-.613-14.162.359-26.538 1.748-37.067 4.277-32.441 6.577-49.89 19.63-59.058 5.623-3.952 15.055-7.638 16.572-5.663"
                  transform="translate(76)"
                />
                <path
                  stroke="#000"
                  stroke-width=".4"
                  d="M783.925 122.109c.612.795-.433 2.023.146 3.884.998 3.213 5.687 4.582 7.68 4.874 5.733.84 12.224-3.845 16.593-11.54 6.456-.569 18.112-.743 31.503 4.041 14.107 5.041 23.327 13.154 27.942 17.833-.743 16.334-1.487 32.67-2.232 49.004.726 35.635 1.451 71.273 2.175 106.908-37.275-.083-74.554-.168-111.83-.25 0 0 .72-14.759.9-17.704-3.335-9.387-7.145-22.426-9.307-38.35-.818-6.013-1.291-11.629-1.52-16.912-.613-14.162.359-26.538 1.748-37.067 4.277-32.441 6.577-49.89 19.63-59.058 5.623-3.952 15.055-7.638 16.572-5.663z"
                  transform="translate(76)"
                />
                <path
                  fill="#263238"
                  d="M758.274 243.908c-1.943 2.029-2.219 5.377-.645 7.774.583.873 1.138 1.212 1.138 1.153 0-.06-.415-.488-.882-1.363-1.315-2.301-1.068-5.315.6-7.308.608-.763 1.077-1.09 1.077-1.16-.499.152-.946.468-1.288.904M767.043 254.155c.18.06.407.16.686.237l.99.228c2.096.354 4.245.21 6.274-.427 2.988-.952 5.743-2.52 8.087-4.604 2.771-2.615 5.282-5.495 7.492-8.598 2.196-3.072 4.713-5.902 7.513-8.44 1.319-1.127 2.644-2.155 3.765-3.263 1.07-1.012 1.927-2.229 2.518-3.577.807-2.025.523-4.32-.754-6.089-.37-.45-.844-.808-1.378-1.047-.185-.076-.377-.13-.575-.167.7.29 1.315.748 1.795 1.334 1.152 1.728 1.364 3.915.565 5.83-.587 1.292-1.43 2.451-2.478 3.41-1.1 1.078-2.417 2.075-3.766 3.202-2.845 2.542-5.403 5.387-7.62 8.489-2.185 3.079-4.658 5.94-7.385 8.551-2.294 2.07-4.995 3.646-7.928 4.624-1.974.648-4.067.845-6.125.574l-.99-.178-.694-.189c-.191-.065-.387-.11-.586-.138.189.104.388.183.594.238M835.855 143.174l-1.33 1.606c-1.164 1.377-2.697 3.472-4.655 6.077-4.89 6.875-9.3 14.09-13.202 21.595-4.616 8.761-8.39 16.894-11.225 22.732l-3.354 6.896-.92 1.886c-.115.213-.209.438-.284.668.147-.193.275-.4.383-.619l.947-1.825c.87-1.598 2.064-3.932 3.512-6.837 2.923-5.787 6.736-13.901 11.353-22.641 3.897-7.48 8.25-14.704 13.036-21.627 1.876-2.634 3.413-4.75 4.527-6.165.518-.67.929-1.25 1.25-1.668.15-.188.289-.39.412-.598-.167.155-.32.33-.45.52"
                  transform="translate(76)"
                />
                <path
                  fill="#000"
                  d="M758.578 252.15c.417 4.897 5.205 9.438 9.935 10.321 4.728.881 9.714-1.106 13.153-4.541 3.37-3.563 5.61-8.08 6.429-12.96-3.54 4.384-8.454 7.384-13.911 8.497-5.404 1.076-10.727 1.36-15.568-1.317"
                  transform="translate(76)"
                />
                <path
                  fill="#263238"
                  d="M859.191 140.323c-.657-.244-1.333-.453-2.021-.624-2.296-.583-4.672-.908-7.066-.97-2.376-.06-4.753.144-7.067.607-.701.139-1.391.31-2.07.511-.259.06-.502.153-.726.276.017.07 1.1-.19 2.877-.475 2.309-.367 4.658-.524 7.006-.468 2.347.083 4.679.372 6.958.866 1.746.37 2.836.7 2.836.63 0-.033-.222-.137-.727-.353"
                  transform="translate(76)"
                />
                <path
                  fill="#FFF"
                  d="M776.275 242.828c.054.337-.065.68-.317.906-.405.367-1.026.33-1.388-.081-.362-.412-.327-1.043.079-1.411l.057-.05c.109-.072.228-.122.355-.15.557-.12 1.106.243 1.223.81l-.009-.024z"
                  transform="translate(76)"
                />
                <g>
                  <path
                    fill="#50AFC8"
                    d="M26.566 69.576c-.92.303-12.118 3.79-19.848-2.754-7.846-6.641-7.77-19.94-3.593-26.65.96-1.537 1.94-2.418 2.5-4.543C7.07 30.14 3.15 26.077 1.984 21.776.866 17.654 1.649 10.996 11.36.312"
                    transform="translate(76) translate(743.82 16.725)"
                  />
                  <path
                    stroke="#000"
                    stroke-width=".5"
                    d="M26.566 69.576c-.92.303-12.118 3.79-19.848-2.754-7.846-6.641-7.77-19.94-3.593-26.65.96-1.537 1.94-2.418 2.5-4.543C7.07 30.14 3.15 26.077 1.984 21.776.866 17.654 1.649 10.996 11.36.312"
                    transform="translate(76) translate(743.82 16.725)"
                  />
                </g>
                <path
                  fill="#142146"
                  d="M819.108 97.398c7.795-.91 13.187-8.296 16.15-15.564 6.053-14.742 6.243-31.523 2.44-46.998-3.803-15.474-13.651-26.988-36.735-33.524-24.555-6.95-69.577 14.01-50.65 55.732"
                  transform="translate(76)"
                />
                <path
                  fill="#F7F7F7"
                  d="M818.92 86.1c1.875-3.374 17.523-32.462 4.17-53.659-1.623-2.578-3.874-5.35-7.048-8.006l-41.84-10.575c-6.7-.712-12.276 3.06-16.283 8.691-1.286 1.804-2.35 3.58-3.24 5.484-2.786 5.952-3.39 11.267-3.634 14.368-1.819 22.934-2.74 34.562 3.953 47.447 3.266 6.286 8.776 16.89 19.59 19.666 12.423 3.193 24.728-5.774 31.215-10.5 6.334-4.613 10.579-9.556 13.118-12.916"
                  transform="translate(76)"
                />
                <path
                  stroke="#000"
                  stroke-width=".5"
                  d="M818.92 86.1c1.875-3.374 17.523-32.462 4.17-53.659-1.623-2.578-3.874-5.35-7.048-8.006l-41.84-10.575c-6.7-.712-12.276 3.06-16.283 8.691-1.286 1.804-2.35 3.58-3.24 5.484-2.786 5.952-3.39 11.267-3.634 14.368-1.819 22.934-2.74 34.562 3.953 47.447 3.266 6.286 8.776 16.89 19.59 19.666 12.423 3.193 24.728-5.774 31.215-10.5 6.334-4.613 10.579-9.556 13.118-12.916z"
                  transform="translate(76)"
                />
                <path
                  fill="#000"
                  d="M758.584 55.813c.14-1.622 1.52-2.819 3.081-2.675 1.6.088 2.846 1.476 2.816 3.142l-.006.084c-.145 1.615-1.522 2.803-3.08 2.652-1.6-.075-2.85-1.464-2.816-3.13l.005-.073M788.102 55.798c.146-1.619 1.525-2.809 3.08-2.66 1.602.076 2.851 1.468 2.815 3.137l-.005.084c-.146 1.619-1.523 2.809-3.08 2.657-1.596-.075-2.846-1.463-2.817-3.125l.007-.093M756.86 49.405c1.381-.829 3.02-1.246 4.684-1.193 1.686.035 3.315.535 4.664 1.428.999.695 1.358 1.324 1.182 1.467-.37.338-2.66-1.1-5.855-1.247-3.195-.146-5.507 1.267-5.866.911-.176-.164.183-.747 1.19-1.366M785.338 49.605c1.339-.907 2.96-1.397 4.625-1.397 1.687-.02 3.34.44 4.728 1.321 1.027.68 1.408 1.321 1.234 1.483-.34.356-2.702-1.097-5.9-1.097-3.197 0-5.447 1.549-5.817 1.19-.185-.16.144-.802 1.13-1.5"
                  transform="translate(76)"
                />
                <path
                  fill="#263238"
                  d="M769.07 75.624c-.431-.003-.862-.065-1.275-.187-.561-.16-1.02-.58-1.242-1.136-.223-1.035-.086-2.12.388-3.059l2.14-5.979c3.086-8.458 5.86-15.213 6.19-15.086.331.128-1.901 7.087-4.997 15.537-.776 2.079-1.524 4.058-2.224 5.941-.394.698-.57 1.507-.504 2.312.123.53.834.687 1.59.764 2.975.403 4.792.725 4.792.903-.01.167-1.865.156-4.857-.01"
                  transform="translate(76)"
                />
                <path
                  fill="#000"
                  d="M787.964 42c3.474-1.205 7.328-.793 10.448 1.117 1.15.788 1.655 1.538 1.441 1.876-.473.714-3.337-.207-6.869-.629-3.53-.421-6.549-.037-6.84-.833-.135-.378.523-1.006 1.82-1.531M756.688 40.94c1.239-.996 2.727-1.546 4.261-1.579 1.533-.077 3.053.375 4.341 1.29.973.743 1.317 1.534 1.094 1.934-.506.822-2.724.222-5.334.288-2.612.066-4.786.857-5.344.066-.242-.4.06-1.21.982-2"
                  transform="translate(76)"
                />
                <path
                  fill="#EB996E"
                  d="M822.983 76.3c.623.43 1.403.55 2.124.329 1.919-.58 3.267-3.145 3.326-5.861.024-1.303-.267-2.591-.846-3.754-.406-1.074-1.33-1.856-2.44-2.07-.456-.055-.915.072-1.278.36-.263.215-.442.516-.513.85-.108.56.12.799.188.78.069-.021-.02-.29.156-.67.236-.526.809-.804 1.359-.66.842.263 1.501.931 1.759 1.787.478 1.053.71 2.2.68 3.356-.039 2.437-1.2 4.716-2.597 5.253-.556.226-1.175.226-1.732 0-.392-.178-.57-.338-.63-.338-.037.02.032.328.444.639"
                  transform="translate(76)"
                />
                <path
                  fill="#142146"
                  d="M827.736 27.02c.349.467 7.77 10.73 3.09 21.18-2.274 5.08-6.323 7.777-8.213 8.861-5.249-5.432-10.498-10.865-15.747-16.3l-42.108-3.981c2.086-3.672 4.174-7.345 6.264-11.018-2.934 3.673-5.864 7.348-8.796 11.021l-10.535.06c.747-4.26 1.493-8.52 2.242-12.78 5.95-4.741 11.899-9.482 17.848-14.225"
                  transform="translate(76)"
                />
                <path
                  fill="#000"
                  d="M774.32 83.354l6.478-2.377c.459-.215.972-.319 1.49-.302 1.228.132 1.8 1.473 1.853 2.595.102 1.25-.231 2.499-.96 3.566-.762 1.06-2.06 1.701-3.455 1.708-1.302-.12-2.484-.743-3.241-1.708-.747-.953-1.335-2-1.748-3.104"
                  transform="translate(76)"
                />
                <path
                  fill="#000"
                  d="M778.433 89.387c.916.22 1.873.182 2.767-.112 1.393-.406 2.562-1.35 3.253-2.625 1.083-2.03.878-4.505-.525-6.33-.184-.244-.43-.435-.71-.555-.309-.1-.64-.1-.947 0-.513.164-.794.33-1.173.484l-1.03.464c-1.347.626-2.53 1.171-3.56 1.657-2.016.966-3.23 1.625-3.17 1.79.062.164 1.43-.248 3.489-1.03 1.04-.4 2.285-.875 3.654-1.43l1.029-.433c.338-.133.76-.36 1.029-.401.268-.041.227-.052.484.216.221.297.406.619.554.957.568 1.344.538 2.868-.083 4.189-.54 1.072-1.46 1.903-2.582 2.337-1.975.791-3.416.226-3.416.42-.02.073.279.29.937.402"
                  transform="translate(76)"
                />
                <path
                  fill="#50AFC8"
                  d="M839.606 77.93c-1.017 1.698-4.035 6.74-8.341 6.678-2.417-.033-4.187-1.662-4.622-2.06-3.138-2.885-3.152-7.433-3.124-10.19.08-7.807 3.735-19.046 10.648-20.125.525-.079 2.444-.352 4.344.702 5.996 3.323 6.09 16.65 1.095 24.995"
                  transform="translate(76)"
                />
                <path
                  stroke="#000"
                  stroke-width=".5"
                  d="M839.606 77.93c-1.017 1.698-4.035 6.74-8.341 6.678-2.417-.033-4.187-1.662-4.622-2.06-3.138-2.885-3.152-7.433-3.124-10.19.08-7.807 3.735-19.046 10.648-20.125.525-.079 2.444-.352 4.344.702 5.996 3.323 6.09 16.65 1.095 24.995z"
                  transform="translate(76)"
                />
                <path
                  fill="#50AFC8"
                  d="M838.273 59.759v-3.867c-.03-2.632-.067-6.287-.108-10.508v-.128h-2.97v.14l.062 2.492c.104 4.02.204 7.763.284 10.896l.08-.115c-1.156.301-2.176 1.032-2.89 2.065-.531.797-.907 1.701-1.105 2.663-.134.608-.213 1.233-.237 1.859v.49c-.005.055-.005.113 0 .17 0 0-.005-.063 0-.17.006-.107.03-.273.03-.485.05-.62.147-1.232.289-1.832.212-.934.594-1.811 1.12-2.58.697-.994 1.69-1.692 2.807-1.974h.08v-.091c-.07-3.154-.155-6.882-.246-10.896-.022-.857-.06-1.699-.06-2.492l-.123.133h2.721l-.12-.125c.083 4.218.148 7.878.197 10.503.031 1.281.08 2.314.08 3.051.028.34.028.61.028.821 0 .093.009.187.024.28.031-.098.05-.198.057-.3"
                  transform="translate(76)"
                />
                <path
                  stroke="#50AFC8"
                  stroke-width=".3"
                  d="M838.273 59.759v-3.867c-.03-2.632-.067-6.287-.108-10.508v-.128h-2.97v.14l.062 2.492c.104 4.02.204 7.763.284 10.896l.08-.115c-1.156.301-2.176 1.032-2.89 2.065-.531.797-.907 1.701-1.105 2.663-.134.608-.213 1.233-.237 1.859v.49c-.005.055-.005.113 0 .17 0 0-.005-.063 0-.17.006-.107.03-.273.03-.485.05-.62.147-1.232.289-1.832.212-.934.594-1.811 1.12-2.58.697-.994 1.69-1.692 2.807-1.974h.08v-.091c-.07-3.154-.155-6.882-.246-10.896-.022-.857-.06-1.699-.06-2.492l-.123.133h2.721l-.12-.125c.083 4.218.148 7.878.197 10.503.031 1.281.08 2.314.08 3.051.028.34.028.61.028.821 0 .093.009.187.024.28.031-.098.05-.198.057-.3z"
                  transform="translate(76)"
                />
                <path
                  fill="#50AFC8"
                  d="M839.257 45.878c-.285-6.031-1.666-15.687-7.512-25.557-2.952-4.985-7.25-12.242-16.284-16.737C807.87-.193 800.647-.17 796.95.11c4.674.707 10.685 2.334 16.66 6.144 19.358 12.344 21.039 36.57 21.224 39.985l4.423-.361"
                  transform="translate(76)"
                />
                <path
                  stroke="#000"
                  stroke-width=".5"
                  d="M839.257 45.878c-.285-6.031-1.666-15.687-7.512-25.557-2.952-4.985-7.25-12.242-16.284-16.737C807.87-.193 800.647-.17 796.95.11c4.674.707 10.685 2.334 16.66 6.144 19.358 12.344 21.039 36.57 21.224 39.985l4.423-.361z"
                  transform="translate(76)"
                />
                <path
                  fill="#000"
                  d="M827.208 82.64c.145-.01-.05-4.461-.067-4.844-.075-1.709-.126-1.891-.144-2.956-.03-1.532.022-3.065.144-4.591.213-2.517.617-5.015 1.21-7.469.406-2.014 1.09-3.962 2.027-5.787.658-1.209 1.57-2.256 2.672-3.073.79-.562 1.287-.768 1.287-.79 0-.018-.136.022-.382.111-.34.136-.668.307-.973.51-1.203.774-2.205 1.823-2.924 3.067-1 1.831-1.734 3.8-2.175 5.842-.62 2.475-1.038 4.998-1.248 7.539-.153 1.72-.2 3.449-.148 5.175.022.703.061 1.432.148 2.472.145 1.766.454 4.803.573 4.795"
                  transform="translate(76)"
                />
                <path
                  fill="#50AFC8"
                  d="M776.34 89.87l.074.371c.299 1.503 1.768 2.48 3.284 2.186l10.073-1.958c1.517-.294 2.503-1.75 2.206-3.253l-.073-.37c-.299-1.502-1.768-2.48-3.284-2.185l-10.073 1.957c-1.517.294-2.503 1.749-2.206 3.251"
                  transform="translate(76)"
                />
                <path
                  stroke="#000"
                  stroke-width=".5"
                  d="M776.34 89.87l.074.371c.299 1.503 1.768 2.48 3.284 2.186l10.073-1.958c1.517-.294 2.503-1.75 2.206-3.253l-.073-.37c-.299-1.502-1.768-2.48-3.284-2.185l-10.073 1.957c-1.517.294-2.503 1.749-2.206 3.251z"
                  transform="translate(76)"
                />
                <path
                  fill="#50AFC8"
                  d="M790.243 89.528l48.347-13.255c.03-.008.057-.016.085-.026 1.181-.38 1.835-1.658 1.46-2.857-.376-1.183-1.622-1.838-2.79-1.468l-47.283 15.057.181 2.549z"
                  transform="translate(76)"
                />
                <path
                  stroke="#000"
                  stroke-width=".25"
                  d="M790.243 89.528l48.347-13.255c.03-.008.057-.016.085-.026 1.181-.38 1.835-1.658 1.46-2.857-.376-1.183-1.622-1.838-2.79-1.468l-47.283 15.057.181 2.549z"
                  transform="translate(76)"
                />
                <path
                  fill="#263238"
                  d="M826.785 79.37l4.335-1.105c1.849-.491 4.037-1 6.484-1.727.672-.185 1.292-.507 1.818-.944.525-.503.82-1.181.819-1.887-.018-.78-.54-1.47-1.319-1.736-.744-.203-1.533-.203-2.277 0-5.595 1.69-10.569 3.303-14.194 4.465l-4.277 1.414c-.529.155-1.047.346-1.547.577.52-.117 1.032-.27 1.53-.464l4.324-1.263 14.215-4.323c.646-.195 1.337-.215 1.996-.056.592.178.995.694 1 1.282.004.582-.237 1.141-.669 1.557-.438.418-.967.738-1.549.944-2.357.728-4.596 1.294-6.414 1.822l-4.334 1.245c-.536.134-1.062.307-1.568.518.552-.057 1.097-.165 1.627-.32"
                  transform="translate(76)"
                />
                <path
                  fill="#F7F7F7"
                  d="M685.905 203.128c2.605 3.655 6.608 8.99 12.01 15.145 0 0 2.633 3 4.874 5.352 10.73 11.26 72.042 18.907 75.58 19.336-.163.867-.231 1.668-.15 2.491.779 7.881 9.825 10.05 14.245 20.575 1.137 2.707 2.098 5.092 1.145 7.365-2.658 6.339-17.301 4.916-17.993 4.841-1.875-.651-5.596-1.955-10.244-3.663-34.228-12.573-63.93-26.858-63.93-26.858-5.34-2.637-10.676-5.273-16.014-7.912-2.423-2.407-10.518-10.504-15.617-16.283-.535-.604-1.478-1.685-2.379-3.28-2.354-4.156-2.31-8.071-2.331-9.74-.142-11.767-4.681-17.294-2.208-19.153 3.929-2.955 18.797 8.468 23.012 11.784"
                  transform="translate(76)"
                />
                <path
                  stroke="#000"
                  stroke-width=".5"
                  d="M685.905 203.128c2.605 3.655 6.608 8.99 12.01 15.145 0 0 2.633 3 4.874 5.352 10.73 11.26 72.042 18.907 75.58 19.336-.163.867-.231 1.668-.15 2.491.779 7.881 9.825 10.05 14.245 20.575 1.137 2.707 2.098 5.092 1.145 7.365-2.658 6.339-17.301 4.916-17.993 4.841-1.875-.651-5.596-1.955-10.244-3.663-34.228-12.573-63.93-26.858-63.93-26.858-5.34-2.637-10.676-5.273-16.014-7.912-2.423-2.407-10.518-10.504-15.617-16.283-.535-.604-1.478-1.685-2.379-3.28-2.354-4.156-2.31-8.071-2.331-9.74-.142-11.767-4.681-17.294-2.208-19.153 3.929-2.955 18.797 8.468 23.012 11.784z"
                  transform="translate(76)"
                />
                <path
                  fill="#000"
                  d="M691.232 297.63c-124.215 0-224.869.199-224.869.466 0 .27 100.682.468 224.82.468 124.135 0 224.817-.198 224.817-.468 0-.252-100.554-.466-224.768-.466"
                  transform="translate(76)"
                />
                <path
                  fill="#142146"
                  d="M792.03 298.097H657.237c0-3.26 2.497-5.903 5.579-5.903h123.636c3.045-.007 5.53 2.575 5.578 5.796v.107zM667.086 50.175h8.676l6.073 216.44h-9.486l-115.444-27.711S554.913 83.86 554.913 81.26l112.173-31.085z"
                  transform="translate(76)"
                />
                <path
                  fill="#142146"
                  d="M609.808 232.296l-7.07 14.044c-2.535 12.403-5.069 24.805-7.605 37.206 14.672 2.888 29.344 5.774 44.016 8.664l7.266 4.903c-23.614-.151-47.227-.304-70.84-.456 3.81-21.493 7.617-42.984 11.425-64.476 7.604.038 15.205.078 22.808.115"
                  transform="translate(76)"
                />
                <path
                  fill="#142146"
                  d="M603.404 249.89L615.914 249.256 611.599 119.042 576.559 122.397 580.419 244.358z"
                  transform="translate(76)"
                />
                <path
                  fill="#266593"
                  d="M862.64 136.757c3.857 3.104 6.778 5.911 7.774 12.685 2.633 17.548-11.483 52.034-11.483 52.034l-11.838 14.208s-34.465 42.939-46.962 53.33c-12.495 10.39-22.325 10.39-22.325 10.39s-8.361-4.497-13.232-11.082c-4.872-6.584-2.435-28.048-2.435-28.048l37.257-42.258s20.785-34.822 32.148-53.934c6.354-10.687 21.383-15.107 31.096-7.325"
                  transform="translate(76)"
                />
                <path
                  stroke="#000"
                  stroke-width=".4"
                  d="M862.64 136.757c3.857 3.104 6.778 5.911 7.774 12.685 2.633 17.548-11.483 52.034-11.483 52.034l-11.838 14.208s-34.465 42.939-46.962 53.33c-12.495 10.39-22.325 10.39-22.325 10.39s-8.361-4.497-13.232-11.082c-4.872-6.584-2.435-28.048-2.435-28.048l37.257-42.258s20.785-34.822 32.148-53.934c6.354-10.687 21.383-15.107 31.096-7.325z"
                  transform="translate(76)"
                />
                <path
                  fill="#142146"
                  d="M891.353 304H380.815c-5.46 0-9.89-1.98-9.89-4.427 0-2.447 4.43-4.427 9.89-4.427h510.538c5.462 0 9.889 1.98 9.889 4.427 0 2.446-4.427 4.427-9.889 4.427"
                  transform="translate(76)"
                />
                <g stroke="#000">
                  <path
                    stroke-width=".8"
                    d="M343.06 106.98c-.408.39-.83.782-1.258 1.184"
                    transform="translate(76) translate(0 101.333)"
                  />
                  <path
                    stroke-dasharray="2.005 3.008"
                    stroke-width="2"
                    d="M337.967 111.679c-20.181 18.171-72.05 31.333-129.875 37.861-30.5 3.444-177.1 1.968-200.22-50.666-14.634-33.31-7.785-61.189 23.2-81.619 45.849-30.23 101.906-3.648 107.164 27.017 3.573 20.821-19.186 19.676-21.645 19.676-7.871 0-11.807-16.725-11.807-19.676 0-5.411 5.903-22.628 43.783-32.466 20.34-5.283 213.961-22.822 311.893-1.476"
                    opacity=".241"
                    transform="translate(76) translate(0 101.333)"
                  />
                </g>
                <path
                  fill="#266593"
                  d="M473.25 295.146L473.25 287.275 527.223 287.275 527.364 295.146z"
                  transform="translate(76)"
                />
                <path
                  fill="#72C9DD"
                  d="M491.496 249.481c-16.984-11.241-14.208-45.671-5.019-49.99 4.479-2.103 9.83 3.247 14.42 0 4.669-3.303 1.024-10.188 6.226-16.066 4.794-5.418 11.283-3.406 12.944-7.87 1.408-3.787-2.982-5.986-3.933-12.624-1.073-7.499 2.595-18.214 10.16-20.003 4.59-1.086 10.622 1.122 12.943 5.412 3.46 6.4-4.091 12.369-1.147 18.033 3.961 7.63 20.522 2.338 24.74 10.493 1.892 3.658.705 8.885-1.64 11.97-4.843 6.376-14.442 2.949-18.676 9.836-1.874 3.047-1.654 6.411-1.609 7.083.43 6.555 6.043 8.949 6.053 15.096.004 1.815-.52 3.81-1.876 6.034-5.447 8.939-34.548 35.198-53.586 22.596"
                  transform="translate(76)"
                />
                <path
                  fill="#FFF"
                  d="M488.992 247.786c.703-.987 1.543-2.32 2.298-3.483l2.321-3.614 4.647-7.233 9.39-14.41c3.16-4.783 6.355-9.545 9.623-14.257 3.271-4.708 6.583-9.393 10.093-13.928-3.395 4.622-6.585 9.384-9.73 14.17-3.146 4.787-6.217 9.624-9.25 14.483l-9.016 14.628-4.458 7.345-2.233 3.675c-.78 1.255-1.417 2.382-2.355 3.744l-1.33-1.12z"
                  transform="translate(76)"
                />
                <path
                  fill="#FFF"
                  d="M501.783 228.472L531.3 222.343 502.176 230.214zM508.67 217.401c4.984-6.836 7.344-15.821 6.322-24.573 1.579 8.654-.173 18.035-4.946 25.58l-1.376-1.007z"
                  transform="translate(76)"
                />
                <path
                  fill="#50AFC8"
                  d="M494.946 266.447c3.554-1.42-.63-10.189 4.761-21.509 4.942-10.38 13.629-13.052 12.85-21.396-.084-.907-.257-4.596-2.83-7.726-7.323-8.905-23.853-1.302-29.507-9.611-.986-1.448.001-.965-1.836-7.141-2.379-7.998-4.044-13.602-9.044-17.607-.77-.617-4.293-3.336-9.635-4.082-2.108-.295-7.617-1.062-12.351 2.31-4.888 3.485-7.924 10.654-6.108 15.778 1.244 3.506 4.673 5.82 4.286 10.492-.063.726-.196 1.293-.356 1.868-2.434 8.886-8.83 13.896-8.298 19.4.423 4.401 5.104 7.306 7.658 8.89 5.48 3.4 9.431 2.24 16.272 3.983 4.038 1.03 9.585 3.201 15.702 8.287 11.231 16.422 16.096 19 18.436 18.064"
                  transform="translate(76)"
                />
                <path
                  stroke="#50AFC8"
                  stroke-width=".3"
                  d="M494.946 266.447c3.554-1.42-.63-10.189 4.761-21.509 4.942-10.38 13.629-13.052 12.85-21.396-.084-.907-.257-4.596-2.83-7.726-7.323-8.905-23.853-1.302-29.507-9.611-.986-1.448.001-.965-1.836-7.141-2.379-7.998-4.044-13.602-9.044-17.607-.77-.617-4.293-3.336-9.635-4.082-2.108-.295-7.617-1.062-12.351 2.31-4.888 3.485-7.924 10.654-6.108 15.778 1.244 3.506 4.673 5.82 4.286 10.492-.063.726-.196 1.293-.356 1.868-2.434 8.886-8.83 13.896-8.298 19.4.423 4.401 5.104 7.306 7.658 8.89 5.48 3.4 9.431 2.24 16.272 3.983 4.038 1.03 9.585 3.201 15.702 8.287 11.231 16.422 16.096 19 18.436 18.064z"
                  transform="translate(76)"
                />
                <path
                  fill="#FFF"
                  d="M486.3 257.76l-.525-2.977-.016-.162-.786-3.671-.894-3.824c-.625-2.54-1.3-5.071-2.045-7.58-1.469-5.027-3.307-9.927-5.525-14.682-2.198-4.762-4.713-9.386-7.405-13.904-2.689-4.52-5.568-8.934-8.644-13.212 3.185 4.198 6.184 8.542 8.998 13 2.816 4.457 5.46 9.03 7.8 13.764 2.355 4.724 4.352 9.647 5.965 14.672.814 2.51 1.564 5.042 2.258 7.586l1.004 3.829.47 1.923.226.967.109.483.056.242.027.136c.02.088.033.18.042.271l-.016-.16.61 2.997-1.708.303z"
                  transform="translate(76)"
                />
                <path
                  fill="#FFF"
                  d="M485.298 250.874c-.608-3.767-1.938-7.487-3.885-10.818-1.93-3.345-4.52-6.336-7.625-8.667-3.1-2.346-6.686-4.064-10.49-4.994-3.796-.943-7.79-1.178-11.693-.643 3.881-.68 7.906-.597 11.791.213 3.892.802 7.625 2.416 10.905 4.714 3.29 2.283 6.094 5.284 8.236 8.694 2.156 3.41 3.685 7.2 4.488 11.206l-1.727.295z"
                  transform="translate(76)"
                />
                <path
                  fill="#FFF"
                  d="M485.934 253.825c-1.11-2.81-1.877-5.702-2.378-8.651-.474-2.951-.622-5.967-.28-8.939.34-2.968 1.206-5.89 2.687-8.467 1.465-2.592 3.564-4.766 5.981-6.409-2.336 1.762-4.3 4.013-5.607 6.615-1.323 2.59-2.022 5.457-2.212 8.34-.19 2.886.092 5.794.69 8.62.616 2.821 1.505 5.602 2.683 8.206l-1.564.685z"
                  transform="translate(76)"
                />
                <path
                  fill="#74BDCE"
                  d="M530.389 252.161c9.062 2.062 16.339 5.458 18.836 2.426 2.559-3.106-.11-12.703-5.043-18.732-1.391-1.703-2.206-2.223-2.2-3.398.03-4.45 11.914-7.664 17.127-12.727 1.928-1.874 4.314-4.818 3.517-7.204-1.046-3.137-7.447-4.373-12.516-3.834-11.582 1.227-14.611 11.543-22.601 10.365-5.855-.863-6.989-6.809-11.303-5.953-5.242 1.04-5.38 10.186-12.187 11.695-2.317.513-4.584-.04-5.9 1.631-1.238 1.57-.621 3.877-.524 4.6 1.017 7.472-5.47 13.855-4.583 21.345 16.169-3.413 28.603-2.212 37.377-.214"
                  transform="translate(76)"
                />
                <path
                  fill="#FFF"
                  d="M498.831 251.689c12.69-13.468 28.23-24.202 45.26-31.314-16.798 7.638-31.875 18.793-43.974 32.466l-1.286-1.152z"
                  transform="translate(76)"
                />
                <path
                  fill="#FFF"
                  d="M507.686 243.907c4.43-5.051 7.816-11.093 9.84-17.629-1.475 6.679-4.38 13.102-8.521 18.693l-1.319-1.064z"
                  transform="translate(76)"
                />
                <path
                  fill="#FFF"
                  d="M502.767 248.77c4.407-5.242 10.518-8.936 17.108-10.169 6.577-1.29 13.496-.126 19.296 3.042-5.935-2.906-12.802-3.708-19.115-2.17-6.322 1.477-12.007 5.31-15.925 10.417l-1.364-1.12z"
                  transform="translate(76)"
                />
                <path
                  fill="#429DB2"
                  d="M501.334 258.725c1.923-.356.715-5.2 4.561-10.362 3.525-4.731 8.147-5.197 8.6-9.484.05-.467.338-2.343-.636-4.183-2.767-5.23-11.814-3.072-13.804-7.836-.35-.83.096-.486-.198-3.787-.38-4.271-.65-7.267-2.75-9.793-.321-.39-1.81-2.118-4.411-3.034-1.027-.363-3.708-1.31-6.419-.087-2.802 1.258-5.046 4.566-4.656 7.333.267 1.894 1.752 3.41 1.084 5.724-.102.36-.23.632-.367.908-2.117 4.232-5.828 6.11-6.118 8.938-.233 2.262 1.817 4.2 2.936 5.26 2.4 2.267 4.496 2.084 7.747 3.657 1.919.929 4.475 2.585 7.025 5.77 3.965 9.415 6.14 11.21 7.406 10.976"
                  transform="translate(76)"
                />
                <path
                  fill="#FFF"
                  d="M500.992 253.825c-.082-2.992-.459-5.98-1.123-8.917-.658-2.933-1.655-5.806-2.828-8.606-2.384-5.588-5.526-10.89-9.032-15.927 3.747 4.873 7.154 10.028 9.844 15.579 1.328 2.782 2.485 5.653 3.31 8.643.827 2.98 1.368 6.045 1.604 9.14l-1.775.088z"
                  transform="translate(76)"
                />
                <path
                  fill="#FFF"
                  d="M500.615 256.386c.63-2.627.868-5.385.497-8.084-.336-2.698-1.255-5.348-2.77-7.612-1.498-2.273-3.64-4.129-6.164-5.203-2.525-1.07-5.349-1.4-8.105-1.114 2.737-.432 5.606-.262 8.263.702 2.655.964 5.01 2.785 6.717 5.098 1.72 2.307 2.84 5.065 3.35 7.92.545 2.857.447 5.818-.086 8.684l-1.702-.391z"
                  transform="translate(76)"
                />
                <path
                  fill="#FFF"
                  d="M502.56 252.841c-.85-1.747-1.486-3.523-1.975-5.359-.481-1.83-.782-3.719-.77-5.616.011-1.891.353-3.809 1.193-5.515.834-1.7 2.112-3.18 3.727-4.17-1.53 1.112-2.655 2.658-3.319 4.343-.67 1.69-.844 3.522-.712 5.313.294 3.59 1.649 7.088 3.425 10.183l-1.568.821z"
                  transform="translate(76)"
                />
                <path
                  fill="#266593"
                  d="M513.256 287.275h-25.495l-10.575-31.482h45.259c-3.064 10.494-6.125 20.99-9.189 31.482"
                  transform="translate(76)"
                />
                <path
                  fill="#266593"
                  d="M524.069 260.712h-48.124l-3.679-12.79h55.098c-1.098 4.265-2.195 8.527-3.295 12.79"
                  transform="translate(76)"
                />
                <path
                  fill="#FFF"
                  d="M216.455 133.799L390.604 133.799 390.604 89.528 216.455 89.528z"
                  transform="translate(76)"
                />
                <path
                  stroke="#266593"
                  stroke-width=".4"
                  d="M216.455 133.799L390.604 133.799 390.604 89.528 216.455 89.528z"
                  transform="translate(76)"
                />
                <path
                  fill="#266593"
                  d="M232.553 103.301h12.082c1.283 0 2.321 1.04 2.321 2.322v12.082c0 1.28-1.038 2.32-2.321 2.32h-12.082c-1.283 0-2.323-1.04-2.323-2.32v-12.082c0-1.282 1.04-2.322 2.323-2.322"
                  transform="translate(76)"
                />
                <path
                  fill="#FFF"
                  d="M236.824 116.09c-.23 0-.454-.075-.638-.214l-3.486-2.65c-.5-.315-.653-.98-.34-1.483.31-.506.967-.658 1.466-.346.055.035.107.073.154.116l2.717 2.056 5.456-5.987c.4-.437 1.076-.464 1.509-.058.432.403.46 1.086.057 1.523l-6.107 6.7c-.202.22-.488.345-.788.344M217.439 221.359L391.588 221.359 391.588 178.071 217.439 178.071z"
                  transform="translate(76)"
                />
                <path
                  stroke="#266593"
                  stroke-width=".4"
                  d="M217.439 221.359L391.588 221.359 391.588 178.071 217.439 178.071z"
                  transform="translate(76)"
                />
                <path
                  fill="#266593"
                  d="M232.553 192.828h12.082c1.283 0 2.321 1.04 2.321 2.321v12.082c0 1.282-1.038 2.322-2.321 2.322h-12.082c-1.283 0-2.323-1.04-2.323-2.322v-12.082c0-1.28 1.04-2.32 2.323-2.32"
                  transform="translate(76)"
                />
                <path
                  fill="#FFF"
                  d="M236.762 204.634c-.233 0-.458-.073-.642-.213l-3.496-2.632c-.471-.356-.566-1.026-.213-1.5.355-.474 1.025-.569 1.497-.213l2.724 2.054 5.471-5.95c.373-.462 1.046-.534 1.503-.164.46.372.532 1.045.161 1.507-.027.035-.06.07-.093.102l-6.154 6.668c-.197.212-.472.336-.758.341M216.455 89.528L390.604 89.528 390.604 45.256 216.455 45.256z"
                  transform="translate(76)"
                />
                <path
                  stroke="#266593"
                  stroke-width=".4"
                  d="M216.455 89.528L390.604 89.528 390.604 45.256 216.455 45.256z"
                  transform="translate(76)"
                />
                <path
                  fill="#266593"
                  d="M232.553 59.03h12.082c1.283 0 2.321 1.04 2.321 2.32v12.082c0 1.282-1.038 2.322-2.321 2.322h-12.082c-1.283 0-2.323-1.04-2.323-2.322V61.35c0-1.28 1.04-2.32 2.323-2.32"
                  transform="translate(76)"
                />
                <path
                  fill="#FFF"
                  d="M236.824 71.819c-.23 0-.454-.074-.638-.215l-3.486-2.649c-.5-.316-.653-.979-.34-1.483.31-.508.967-.66 1.466-.347.055.036.107.074.154.118l2.717 2.056 5.456-5.987c.399-.436 1.07-.466 1.504-.065.434.4.466 1.082.07 1.52l-.008.01-6.107 6.698c-.202.223-.488.347-.788.344"
                  transform="translate(76)"
                />
                <path
                  fill="#263238"
                  d="M244.258 165.089s.234 0 .636-.15c.586-.185 1.073-.593 1.358-1.136.417-.689.201-1.718.235-2.888.032-1.166 0-2.493 0-3.95V150.864c.036-.425 0-.85-.107-1.262-.274-.83-1.027-1.407-1.9-1.457h-12.66c-1.013.008-1.878.729-2.068 1.72-.075.989 0 2.125 0 3.186v8.907c-.03.43-.01.867.062 1.294.087.403.29.771.584 1.061.276.273.621.463.997.552.38.075.769.1 1.157.075h2.303l6.929.086 1.89.051c.22-.023.44-.023.66 0-.218.05-.439.066-.66.054l-1.89.053-6.929.086h-2.303c-.422.03-.846.007-1.262-.065-.946-.213-1.693-.938-1.932-1.878-.092-.465-.12-.94-.085-1.412v-10.499c-.036-.555-.036-1.113 0-1.666.235-1.26 1.33-2.173 2.611-2.176h12.608c1.068.075 1.986.785 2.324 1.804.122.464.164.945.127 1.422v11.678c.014.477-.1.949-.33 1.369-.33.568-.879.98-1.516 1.136-.267.105-.556.142-.839.107M331.57 63.95c0 .163-15.392.302-34.436.302s-34.436-.14-34.436-.303c0-.165 15.392-.305 34.436-.305s34.436.128 34.436.305M374.861 70.835c0 .177-25.333.305-56.568.305-31.236 0-56.579-.128-56.579-.305 0-.175 25.332-.305 56.58-.305 31.244 0 56.567.165 56.567.305M331.57 152.49c0 .166-15.392.306-34.436.306s-34.436-.14-34.436-.305c0-.163 15.392-.303 34.436-.303s34.436.128 34.436.303M374.861 160.362c0 .164-25.333.305-56.568.305-31.236 0-56.579-.141-56.579-.305 0-.163 25.332-.305 56.58-.305 31.244 0 56.567.142 56.567.305M331.57 106.252c0 .164-15.392.305-34.436.305s-34.436-.141-34.436-.305c0-.165 15.392-.305 34.436-.305s34.436.14 34.436.305M374.861 114.122c0 .177-25.333.305-56.568.305-31.236 0-56.579-.128-56.579-.305 0-.175 25.332-.303 56.58-.303 31.244 0 56.567.14 56.567.303M331.57 194.797c0 .163-15.392.303-34.436.303s-34.436-.14-34.436-.303c0-.165 15.392-.305 34.436-.305s34.436.116 34.436.305M374.861 202.667c0 .165-25.333.305-56.568.305-31.236 0-56.579-.14-56.579-.305 0-.164 25.332-.305 56.58-.305 31.244 0 56.567.141 56.567.305"
                  transform="translate(76)"
                />
              </g>
            </g>
            <path
              fill="#92B8B1"
              d="M538.5 101.5L560 116.5 535 120.5z"
              transform="translate(-431 -595) translate(431 596)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
