import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { Button } from "site-ui/src/components/Button";
import { PageWrapper } from "site-ui/src/components/PageWrapper";
import { Section } from "site-ui/src/components/Section";
import { Link, graphql } from "gatsby";
import { Illustration } from "../components/illustration";
import { openLiveChat } from "site-ui/src/components/LiveChat";
import { Accordion } from "site-ui/src/components/Accordion";
import { renderRichText } from "twill-contentful-text-render";
import { Trans, useTranslation } from "twill-net-localization";
import styled from "styled-components";
import { useLocale } from "./../../../gatsby-theme-twill-website";
import { getSectionRoute } from "./../../../tools/ui/helpers/getSectionRoute";
import { Sections } from "./../../../tools/ui/constants";

import emailIcon from "./icons/email.svg";
import chatIcon from "./icons/chat.svg";
import officeIcon from "./icons/office.svg";
import { Helmet } from "react-helmet";

export default ({ data }) => {
  const {
    contentfulFaqMainPageSettings: { featuredQuestions },
  } = data;
  const questions = featuredQuestions.map(question => ({
    ...question,
    question: question.question.question,
    answer: renderRichText(question.answer),
    answerText: renderRichText(question.answer),
  }));
  const { t } = useTranslation();

  const locale = useLocale();
  const faqLink = getSectionRoute(locale, Sections.FAQ);

  return (
    <div>
      <Helmet>
        <title>{t("contactPage.contactTwill", "Contact Twill")}</title>
      </Helmet>
      <PageWrapper variant="striped">
        <Section
          align="center"
          heading={
            <Typography variant="h1">
              {t("contactPage.contactTwill", "Contact Twill")}
            </Typography>
          }
        >
          <Box
            mx="auto"
            textAlign={{ sm: "left", md: "center" }}
            maxWidth={600}
          >
            <Typography variant="subtitle1">
              <Trans i18nKey="contactPage.questionAboutTwill" values={{}}>
                Do you have questions? Take a look at our frequently asked
                questions section, if you cannot find the answer, please contact
                us. One of our colleagues in your region will be ready to help
                you.
              </Trans>
            </Typography>
            <Box mt={8}>
              <Link to={`./../${faqLink}`}>
                <Button>
                  <Trans i18nKey="contactPage.goToFAQ" values={{}}>
                    Go to FAQ
                  </Trans>
                </Button>
              </Link>
            </Box>
          </Box>
          <Illustration
            style={{
              margin: "32px auto 0",
              display: "block",
              maxWidth: "100%",
            }}
          />{" "}
        </Section>
        <Section>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={4}
          >
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Box
                minHeight="100%"
                paddingLeft="1.5rem"
                paddingRight="1.5rem"
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-start"
                gridGap="1rem"
              >
                <StyledMobileIcon
                  src={emailIcon}
                  alt="Email icon"
                  onlyMobile={true}
                />
                <div>
                  <StyledDesktopIcon
                    src={emailIcon}
                    alt="Email icon"
                    onlyMobile={true}
                  />
                  <Trans i18nKey="contactPage.contactInfo" values={{}}>
                    <Typography variant="h3" gutterBottom>
                      Email
                    </Typography>
                    <Typography>
                      Send us an email and we will respond as soon as possible.
                    </Typography>
                    <Typography>
                      <a href="mailto:twillsupport@maersk.com">Send email</a>
                    </Typography>
                  </Trans>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} spacing={4}>
              <Box
                minHeight="100%"
                paddingLeft="1.5rem"
                paddingRight="1.5rem"
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-start"
                gridGap="1rem"
              >
                <StyledMobileIcon src={chatIcon} alt="Chat icon" />
                <div>
                  <StyledDesktopIcon src={chatIcon} alt="Chat icon" />
                  <Trans i18nKey="contactPage.chat247" values={{}}>
                    <Typography variant="h3" gutterBottom>
                      Chat with us
                    </Typography>
                    <Typography>
                      If you have questions about a shipment, our services or
                      logistics in general.
                    </Typography>
                    <Typography>
                      <a onClick={openLiveChat} href="#">
                        Start a conversation
                      </a>
                    </Typography>
                  </Trans>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Box
                minHeight="100%"
                paddingLeft="1.5rem"
                paddingRight="1.5rem"
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-start"
                gridGap="1rem"
              >
                <StyledMobileIcon src={officeIcon} alt="Office icon" />
                <div>
                  <StyledDesktopIcon src={officeIcon} alt="Office icon" />
                  <Trans i18nKey="contactPage.localOffices" values={{}}>
                    <Typography variant="h3" gutterBottom>
                      Local offices
                    </Typography>
                    <Typography>
                      <p>
                        Search for the details of the local office in your
                        region.
                      </p>
                    </Typography>
                  </Trans>
                  <Typography>
                    <Link to={`./local-offices`}>
                      {t("contactPage.goToOffice", "Find your local office")}
                    </Link>
                  </Typography>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Section>
        <Section
          variant="darker"
          align="center"
          heading={
            <Typography variant="h2">
              <Trans i18nKey="common.faqLong" values={{}}>
                Frequently asked questions
              </Trans>
            </Typography>
          }
        >
          <Accordion questions={questions} />
        </Section>
      </PageWrapper>
    </div>
  );
};

export const pageQuery = graphql`
  query ContactPageQuery($locale: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentfulFaqMainPageSettings(node_locale: { eq: $locale }) {
      featuredQuestions {
        id
        seoMeta {
          slug
        }
        question {
          question
        }
        answer {
          raw
        }
      }
    }
  }
`;

const StyledMobileIcon = styled.img`
  width: 2rem;
  padding-top: 0.7rem;
  display: block;
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: none;
  }
`;

const StyledDesktopIcon = styled(StyledMobileIcon)`
  display: none;
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: block;
  }
`;
